import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { config, animated, useSpring } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import Copyright from '../components/Copyright'
import { useSiteMetadata } from '../utils'
import { ChildImageSharp } from '../types'
import theme from '../../config/theme'

type PageProps = {
  data: {
    projects: {
      nodes: {
        title: string
        slug: string
        cover_desktop: ChildImageSharp
        cover_mobile: ChildImageSharp
      }[]
    }
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 50vw;

  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 60vw;

    > a {
      margin-bottom: ${props => props.theme.space[6]};
    }
  }
`

const Index: React.FunctionComponent<PageProps> = ({ data: { projects } }) => {
  const site = useSiteMetadata()

  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout FooterComponent={Copyright}>
      <SEO />
      <Area style={pageAnimation}>
        {projects.nodes.map(project => (
          <GridItem key={project.slug} to={project.slug} aria-label={`View project "${project.title}"`}>
            <Img
              fluid={[
                project.cover_desktop.childImageSharp.fluid,
                {
                  ...project.cover_mobile.childImageSharp.fluid,
                  media: `(max-width: ${theme.breakpoints[2]})`,
                },
              ]}
            />
            <span>{project.title}</span>
          </GridItem>
        ))}
      </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Projects {
    projects: allProjectsYaml {
      nodes {
        title
        slug
        cover_desktop {
          childImageSharp {
            fluid(quality: 95, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cover_mobile {
          childImageSharp {
            fluid(quality: 95, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
